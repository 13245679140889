import React, { useEffect } from 'react';
import styles from '../styles/homeStyles.module.css';
import { NavLink } from '../header/HeaderElements';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';

import imageSmall from '../../images/butterfield8/mark-belford-butterfield8-Lrg-2.webp';
import imageMedium from '../../images/butterfield8/mark-belford-butterfield8-Lrg-2.webp';
import imageLarge from '../../images/butterfield8/mark-belford-butterfield8-Lrg-1.webp';
import imageXLarge from '../../images/butterfield8/mark-belford-butterfield8-Lrg-1.webp';
import landingTextImage from '../../images/elements/Gorgeous.svg';
import hiddenTextMask from '../../images/elements/hidden-text-mask.svg';

const MarkBelfordWebsite = () => {
    return(
        <main>
            <section className={`content ${styles.landingPageTop} ${styles.landingBackground}`}>
                <div className={styles.landingPageTopInner}>
                    <div className='row'>
                        <div className={`col-lg-6 landing-text ${styles.landingTextImg}`}>
                            <picture>
                                <img src={landingTextImage} alt='text saying You are Gorgeous' height='100%' width='100%' />
                            </picture>
                        </div>
                    </div>
                </div>
                <div className='header-marker'></div>
            </section>
            <section className={`content large-adjust ${styles.homeSectionBTF1}`}>
                <div className={styles.belowTheFoldHeader}>
                    <h1><div className={styles.adj1}>an</div><div className={styles.adj2}>Expression</div><div className={styles.adj3}>of</div><div className={styles.adj4}>Beauty</div></h1>
                </div>
                <div className='row'>
                    <div className='col-xl-2 col-xxl-3 lrg-1'></div>
                    <div className={`col-xl-8 col-xxl-6 ${styles.contentTopParagraph}`}>
                        <h5>"Mark Belford's prêt-à-porter capsule collection pays homage to the couture portfolio by featuring quality fabrics, structure and contemporary approachability."</h5>
                    </div>
                    <div className='col-xl-2 col-xxl-3 lrg-1'></div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className={`col-md-6 col-lg-8 col-xxl-6 ${styles.fullBleedFlushLeft}`}>
                        <NavLink to="/ParkAvenue">
                        <figure>
                            <picture className='image'>
                                <source media='(max-width:500px)' srcSet={require('../../images/home/mark-belford-black-lace-2-xs.webp')} />
                                <source media='(max-width:824px)' srcSet={require('../../images/home/mark-belford-black-lace-2-sm.webp')} />
                                <source srcSet={require('../../images/home/mark-belford-black-lace-2.webp')} /> 
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords black lace Park Avenue Dress' height='100%' width='100%' />
                            </picture>
                            <div className={styles.imageLinkCaption}>
                                <div className={styles.line1}>
                                view the
                                </div>
                                <div className={styles.line2}>
                                Park Avenue
                                </div>
                                <div className={styles.line3}>
                                Collection
                                </div>
                            </div>
                        </figure>
                        </NavLink>
                    </div>
                    <div className='col-md-11 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                <h2 className='call-out-text'>the ultimate expression<br/>of a client's<br/>inner beauty</h2>
                                <p>Mark Belford Couture was founded in Toronto, Canada in 2000 by Mark Belford and discovered by Giorgio Beverly Hills with his first capsule Couture Collection that year, officially launching at Giorgio’s for The Academy Awards.</p>
                                { /* <div className='e-comerce-link-2' > */ }
                                <div className='e-comerce-link-2' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                    <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                    <div className='hidden-text-mask'></div>
                                    <div className='hidden-text'>
                                        You're<br/>Worth It!
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12'>
                                <NavLink to="/Butterfield8">
                                <figure>
                                    <picture className='image'>
                                        <img srcSet={`${imageSmall} 320w, ${imageMedium} 680w, ${imageLarge}   960w, ${imageXLarge} 1980w`} src={imageXLarge} alt="Model wearing Mark Belfords Butterfield 8 Dress" height='auto' width='100%' />
                                    </picture>
                                    <div className={styles.imageLinkCaption}>
                                        <div className={styles.line1}>
                                        view the
                                        </div>
                                        <div className={styles.line2}>
                                        Butterfield 8
                                        </div>
                                        <div className={styles.line3}>
                                        Collection
                                        </div>
                                    </div>
                                </figure>
                                </NavLink>
                            </div>
                            <div className='row'>
                                <div className='col-xs-12 col-md-6 col-xxl-9'>
                                    <br/>
                                    <h2 style={{color:'#fd1d86'}}>Impeccable<br/>tailoring and<br/>architectural<br/>hand draping</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg'>
                            <br/><br/>Mark Belford is instantly recognized by the impeccable tailoring and the architectural hand draping that are the cornerstones of his creative process. Inspired by his passion for Film, Theatre, Architecture, and a home surrounded in love and music, Mark’s vision expands into multiple artistic endeavors, including his work in film and television. The Red Carpet at the Oscar’s, The Emmy’s and Solo Exhibits at the most prestigious museums, The Royal Ontario Museum Toronto, and The Moca Gala Los Angeles.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <NavLink to="/AcademyGown">
                                <figure>
                                    <picture className='image'>
                                        <source srcSet={require('../../images/home/mark-belford-silver-dress-1.webp')} /> 
                                        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords silver Academy Gown' height='100%' width='100%' />
                                    </picture>
                                    <div className={styles.imageLinkCaption}>
                                        <div className={styles.line1}>
                                        view the
                                        </div>
                                        <div className={styles.line2}>
                                        Academy Gown
                                        </div>
                                        <div className={styles.line3}>
                                        Collection
                                        </div>
                                    </div>
                                </figure>
                                </NavLink>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            Mark Belford is instantly recognized by the impeccable tailoring and the architectural hand draping that are the cornerstones of his creative process. Inspired by his passion for Film, Theatre, Architecture, and a home surrounded in love and music, Mark’s vision expands into multiple artistic endeavors, including his work in film and television. The Red Carpet at the Oscar’s, The Emmy’s and Solo Exhibits at the most prestigious museums, The Royal Ontario Museum Toronto, and The Moca Gala Los Angeles.<br/>
                            <br/>
                            <br/>
                            </p>  
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default MarkBelfordWebsite;