import React from 'react';
import styles from '../styles/Butterfield8.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';

const Butterfield8 = () => {
    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top large-adjust'>
                <div className='row'>
                    <div className='col-sm-1 col-xl-2 col-xxl-3'></div>
                    <div className='col-sm-6 col-lg-4 col-xl-3 col-xxxl-2 order-md-2'>
                        <div className={styles.heroHeader}>
                            <h1>
                                <div className={styles.adj1}>the</div><div className={styles.adj2}>Butterfield</div><div className={styles.adj3}>8</div>
                            </h1>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-3 col-xxxl-2 order-md-1'>
                        <picture className='image'>
                            <source media='(max-width:500px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-xs-5.webp')} />
                            <img src={require('../../images/butterfield8/mark-belford-butterfield8-Lrg-5.webp')} alt='Close up of the Butterfield 8 dress' height='auto' width='100%' />
                        </picture>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3 col-xl-3 col-xxl-4'></div>
                    <div className='col-md-6 col-xl-5 col-xxl-4 col-xxxl-3'>
                        <div className={stylesCollection.contentTopParagraph}>
                            <p>
                            In the alluring realm of Hollywood, where elegance and sensuality intertwine, a Couture Editorial inspired by Elizabeth Taylor's passionate and fierce portrayal of Gloria Wondrous in "Butterfield 8" unfolds.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className='row'>
                    <div className={`col-md-6 col-lg-8 col-xxl-6 ${stylesCollection.fullBleedFlushLeft}`}>
                        <figure>
                            <picture className='image'>
                                <source media='(max-width:500px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-xs-2.webp')} />
                                <source media='(max-width:824px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-sm-2.webp')} />
                                <source srcSet={require('../../images/butterfield8/mark-belford-butterfield8-Lrg-2.webp')} />
                                <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords Butterfield 8 Dress' height='100%' width='100%' loading='lazy' />
                            </picture>
                        </figure>
                    </div>
                    <div className='col-md-12 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block'>
                                    <h2 style={{color:'#fd1d86'}}>
                                        An Extra<br/>Touch of<br/>Allure
                                    </h2>
                                    <p>As the scene unfolds, Gloria, brought to life by the mesmerizing Elizabeth Taylor, fearlessly resets the narrative as the Lady of the evening. Taking center stage is a silk wool dress, flawlessly tailored with a commanding front sash. A coordinating coat, fashioned from the finest Italian cashmere, boasts a voluminous collar adorned with vintage fox fur. The bias-cut fur creates an enchanting chevron pattern, adding an extra touch of allure.</p>
                                    <div className='e-comerce-link-2' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-xs-1.webp')} />
                                    <source media='(max-width:824px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-sm-1.webp')} />
                                    <source srcSet={require('../../images/butterfield8/mark-belford-butterfield8-Lrg-1.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords Butterfield 8 Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-md-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-xs-5.webp')} />
                                    <source srcSet={require('../../images/butterfield8/mark-belford-butterfield8-Lrg-5.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords Butterfield 8 Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-xxl-8'>
                                <h2 style={{color:'#fd1d86'}}>Extraordinary<br/>Allure and<br/>Grace</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg top-adj-1'>
                            In the spirit of Elizabeth Taylor's extraordinary allure and grace, Mark Belford expresses deep admiration, transcending the boundaries of time and space. The Couture Editorial becomes a celebration of their imagined friendship, a connection forged through their shared love for beautful artistry and the creation of something truly exceptional.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <picture className='image'>
                                    <source media='(max-width:500px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-xs-3.webp')} />
                                    <source media='(max-width:824px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-sm-3.webp')} />
                                    <source srcSet={require('../../images/butterfield8/mark-belford-butterfield8-Lrg-3.webp')} />
                                    <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords Butterfield 8 Dress' height='100%' width='100%' loading='lazy' />
                                </picture>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            In the spirit of Elizabeth Taylor's extraordinary allure and grace, Mark Belford expresses deep admiration, transcending the boundaries of time and space. The Couture Editorial becomes a celebration of their imagined friendship, a connection forged through their shared love for beautful artistry and the creation of something truly exceptional.<br/>
                            <br/>
                            <br/>
                            </p>  
                        </div>
                    </div>
                </div>
            </section>
            <section className='content landing-page-bottom'>
                <div className='row'>
                    <div className='col-md-11 col-lg-9 pad-bottom-24'>
                        <picture className='image'>
                            <source media='(max-width:500px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-xs-4.webp')} />
                            <source media='(max-width:824px)' srcSet={require('../../images/butterfield8/mark-belford-butterfield8-sm-4.webp')} />
                            <source srcSet={require('../../images/butterfield8/mark-belford-butterfield8-Lrg-4.webp')} />
                            <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt='Model wearing Mark Belfords Butterfield 8 Dress' height='100%' width='100%' loading='lazy' />
                        </picture>
                    </div>
                    <div className='col-md-8 col-lg-3 col-xl-2'>
                        <p>
                        Within the enchanting world of Hollywood's glamour, where desire intertwines with vulnerability, this Couture Editorial breathes life into the very essence of Gloria as a modern woman, fighting against discrimination and societal judgment.<br/>
                            <br/>
                        </p>
                    </div>             
                </div>
                <div className='row'>
                    <div className='col-sm-2'></div>
                    <div className={`col-sm-6 ${stylesCollection.specsBlock}`}>
                        <div className={stylesCollection.catagoryType}>Art Director</div> <div className={stylesCollection.catagoryName}>Mark Belford</div>
                        <div className={stylesCollection.catagoryType}>Wardrobe</div> <div className={stylesCollection.catagoryName}>Mark Belford Couture</div>
                        <div className={stylesCollection.catagoryType}>Production</div> <div className={stylesCollection.catagoryName}>Manager Enzo Avola</div>
                        <div className={stylesCollection.catagoryType}>Hair/Makeup</div> <div className={stylesCollection.catagoryName}>L’Oréal Paris</div>
                        <div className={stylesCollection.catagoryType}>Jewellery</div> <div className={stylesCollection.catagoryName}>Birks</div>
                        <div className={stylesCollection.catagoryType}>Photography/Post</div> <div className={stylesCollection.catagoryName}>Oz John Tekson</div>
                        <div className={stylesCollection.catagoryType}>Location</div> <div className={stylesCollection.catagoryName}>The Shangri La Hotel Toronto</div><br/><br/><br/><br/><br/>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Butterfield8;