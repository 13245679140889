import React from 'react';
import styles from '../styles/ParkAvenue.module.css';
import stylesCollection from '../styles/Collections.module.css';
import showHiddenText from '../modules/ShowText';
import removeHiddenText from '../modules/RemoveText';

const ParkAvenue = () => {
    return(
        <main>
            <div className='page-marker'></div>
            <section className='content content-top large-adjust'>
                <div className='row'>
                    <div className='col-sm-1 col-xl-2 col-xxl-3'></div>
                    <div className='col-sm-6 col-lg-4 col-xl-3 col-xxxl-2 order-md-2'>
                        <div className={styles.heroHeader}>
                            <h1>
                                <div className={styles.adj1}>the</div><div className={styles.adj2}>Park</div><div className={styles.adj3}>Avenue</div>
                            </h1>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4 col-xl-3 col-xxxl-2 order-md-1'>
                        <picture className='image'>
                            <img src={require('../../images/park-avenue/mark-belford-park-avenue-top.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                        </picture>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3 col-xl-3 col-xxl-4'></div>
                    <div className='col-md-6 col-xl-5 col-xxl-4 col-xxxl-3'>
                        <div className={stylesCollection.contentTopParagraph}>
                            <p>
                                Elevate your Park Avenue style with our captivating creation, the "Park Avenue" Couture cocktail dress. Crafted from luxurious deep Bordeaux-colored French silk chiffon, this exquisite masterpiece embodies the perfect blend of sophistication and sensuality.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content'>
                <div className='row'>
                    <div className="col-md-6 col-lg-6 col-xxl-6">
                        <div className={stylesCollection.fullBleed}>
                            <figure>
                                <picture className='image-full'>
                                    <img src={require('../../images/park-avenue/mark-belford-park-avenue-Lrg-1.webp')} alt='Mark Belford Silver Gown' height='100%' width='100%' />
                                </picture>
                            </figure>
                        </div>
                    </div>
                    <div className='col-md-6 col-lg-5 col-xl-6'>
                        <div className='row'>
                            <div className='col-xxl-2'></div>
                            <div className='col-sm-10 col-md-12 col-xl-10 col-xxl-8'>
                                <div className='vertical-center-block blk'>
                                    <h2 style={{color:'#fd1d86'}}>
                                        An Aura<br/>of Elegance<br/>and Grace
                                    </h2>
                                    <p className='white-text'>Prepare to be mesmerized by the structured corseted bodice, meticulously designed to accentuate your curves and create a flawless silhouette. Mark's signature cups enhance and celebrate your natural beauty, adding an extra touch of allure. Witness the artistry of razor-sharp rouching that delicately highlights the sensuality of your curves, creating an enchanting visual effect. With every step, you'll exude an aura of elegance and grace, effortlessly embodying the essence of Park Avenue style.</p>
                                    <div className='e-comerce-link-2 blk-bg' onMouseEnter={() => {showHiddenText();}} onMouseLeave={() => {removeHiddenText();}} >
                                        <a href='https://www.markbelfordcouture.com/' target='blank' >Shop<br/>Mark Belford<br/>Couture</a>
                                        <div className='hidden-text-mask'></div>
                                        <div className='hidden-text'>
                                            You're<br/>Worth It!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content middle-big-pad'>
                <div className='row'>
                    <div className='col-lg-6 order-lg-2 order-xs-1'>
                        <div className='row'>
                            <div className='col-md-10 col-lg-12'>
                                <picture className='image'>
                                    <img src={require('../../images/park-avenue/mark-belford-park-avenue-Lrg-3.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-md-10 col-lg-8'>
                                <picture className='image'>
                                    <img src={require('../../images/park-avenue/mark-belford-black-lace-2.webp')} alt='Model standing by mirror in the Butterfield 8 dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <div className='col-xs-12 col-md-6 col-xxl-8'>
                                <h2 style={{color:'#fd1d86'}}>Park Avenue<br/>Exclusivity</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 order-lg-1 order-xs-2'>
                        <div className='row justify-content-end-lg'>
                            <p className='lrg top-adj-1'>
                            Experience the magic of this Couture piece and let it transform you into the epitome of glamour and sophistication. Discover the allure of Park Avenue exclusivity.<br/>
                            <br/>
                            </p>
                            <div className='col-xs-12 col-md-8 col-lg-10 col-xxxl-8'>
                                <picture className='image'>
                                    <img src={require('../../images/park-avenue/mark-belford-park-avenue-Lrg-4.webp')} alt='Model looking into mirror in the Butterfield 8 coat and dress' height='auto' width='100%' />
                                </picture>
                            </div>
                            <p className='sml'>
                            <br/>
                            <br/>
                            Experience the magic of this Couture piece and let it transform you into the epitome of glamour and sophistication. Discover the allure of Park Avenue exclusivity.<br/>
                            <br/>
                            <br/>
                            </p>  
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ParkAvenue;